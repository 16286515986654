<template>
  <div>
    <ul
      v-if="open"
      class="flix-flex flix-flex-list flix-gap-5"
      style="flex-direction: row"
    >
      <li>
        <a href="#" class="flix-btn flix-btn-info" @click.prevent="addHeader"
          ><icon id="plus" /> {{ $t("form.headings.title") }}
          <span>({{ getCount().header || 0 }})</span></a
        >
      </li>
      <li>
        <a href="#" class="flix-btn flix-btn-info" @click.prevent="addParagraph"
          ><icon id="circle-info" /> {{ $t("form.paragraph.title") }}
          <span>({{ getCount().p || 0 }})</span></a
        >
      </li>
      <li>
        <a href="#" class="flix-btn flix-btn-info" @click.prevent="addLink"
          ><icon id="link" /> {{ $t("form.link.title") }}
          <span>({{ getCount().a || 0 }})</span></a
        >
      </li>
      <li v-if="checkDoublicate('tel')">
        <a href="#" class="flix-btn flix-btn-info" @click.prevent="addTel"
          ><icon id="plus" /> {{ $t("form.phone.title") }}
          <span>({{ getCount().tel || 0 }})</span></a
        >
      </li>
      <li>
        <a href="#" class="flix-btn flix-btn-info" @click.prevent="addText"
          ><icon id="plus" /> {{ $t("form.text.title") }}
          <span>({{ getCount().text || 0 }})</span></a
        >
      </li>
      <li>
        <a href="#" class="flix-btn flix-btn-info" @click.prevent="addTextarea"
          ><icon id="plus" /> {{ $t("form.textarea.title") }}
          <span>({{ getCount().textarea || 0 }})</span></a
        >
      </li>
      <li>
        <a href="#" class="flix-btn flix-btn-info" @click.prevent="addRadio"
          ><icon id="plus" /> {{ $t("form.radio.title") }}
          <span>({{ getCount().radio || 0 }})</span></a
        >
      </li>
      <li>
        <a href="#" class="flix-btn flix-btn-info" @click.prevent="addCheckbox"
          ><icon id="plus" /> {{ $t("form.checkbox.title") }}
          <span>({{ getCount().checkbox || 0 }})</span></a
        >
      </li>
      <li v-if="checkDoublicate('agb')">
        <a href="#" class="flix-btn flix-btn-info" @click.prevent="addAGB"
          ><icon id="plus" /> {{ $t("form.terms.title") }}
          <span>({{ getCount().agb || 0 }})</span></a
        >
      </li>
    </ul>

    <a
      href="#"
      v-else
      @click.prevent="open = !open"
      class="flix-btn flix-btn-info flix-btn-invert flix-btn-block"
      ><icon id="plus" /> {{ $t("form.add") }}</a
    >
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    index: [Number, String]
  },
  data() {
    return {
      open: true
    };
  },
  computed: {},
  mounted() {},
  methods: {
    getCount() {
      var data = JSON.parse(JSON.stringify(this.$store.state.business.unsaved))
        .form;

      var c = {};
      data.forEach(function(d) {
        var type = d.type;
        if (type === "text") {
          type = d.subtype;
        }
        if (type === "paragraph") {
          type = d.subtype;
        }
        if (typeof c[type] === "undefined") {
          c[type] = 0;
        }
        c[type] = c[type] + 1;
      });
      return c;
    },
    addLink() {
      var copy = {
        type: "paragraph",
        subtype: "a",
        label: "Link",
        columnName: "12",
        description: "<a href=''>Link</a>",
        required: false,
        value: ""
      };
      this.setSave(copy);
    },
    addCheckbox() {
      var copy = {
        type: "checkbox",
        subtype: "checkbox",
        label: "Mehrfachauswahl",
        columnName: "12",
        description: "",
        required: false,
        value: "",
        values: [
          { label: "Option 1", value: "Option 1" },
          { label: "Option 2", value: "Option 2" },
          { label: "Option 3", value: "Option 3" }
        ]
      };
      this.setSave(copy);
    },
    addRadio() {
      var copy = {
        type: "radio",
        subtype: "radio",
        label: "Einzelauswahl",
        columnName: "12",
        description: "",
        required: false,
        value: "",
        values: [
          { label: "Option 1", value: "Option 1" },
          { label: "Option 2", value: "Option 2" },
          { label: "Option 3", value: "Option 3" }
        ]
      };
      this.setSave(copy);
    },
    addAGB() {
      var copy = {
        type: "agb",
        subtype: "agb",
        label: "Ich stimme den AGBs zu",
        columnName: "12",
        description: "",
        required: true,
        value: "",
        values: ["yes", "no"]
      };
      this.setSave(copy);
    },
    addTel() {
      var copy = {
        type: "text",
        subtype: "tel",
        label: "Handynummer",
        columnName: "12",
        description: "",
        required: false
      };
      this.setSave(copy);
    },
    addText() {
      var copy = {
        type: "text",
        subtype: "text",
        label: "",
        columnName: "12",
        description: "",
        required: false
      };
      this.setSave(copy);
    },
    addTextarea() {
      var copy = {
        type: "textarea",
        subtype: "textarea",
        label: "",
        columnName: "12",
        description: "",
        required: false
      };
      this.setSave(copy);
    },
    addParagraph() {
      var copy = {
        type: "paragraph",
        subtype: "p",
        label: "",
        columnName: "12",
        description: "",
        required: false
      };
      this.setSave(copy);
    },
    addHeader() {
      var copy = {
        type: "header",
        subtype: "h2",
        label: "",
        columnName: "12",
        description: "",
        required: false
      };
      this.setSave(copy);
    },
    setSave(copy) {
      copy.id = new Date().getTime();
      var data = JSON.parse(JSON.stringify(this.$store.state.business.unsaved));
      var pos = this.index;

      if (pos === "top") {
        pos = 0;
        data.form.splice(pos, 0, copy);
      }
      if (pos === "bottom") {
        data.form.push(copy);
        pos = data.form.length - 1;
      }

      this.$store.commit("business/prefetch", data);
      this.$store.commit("form/setEdit", pos);
    },
    checkDoublicate(part) {
      var form = this.$store.state.business.unsaved.form;
      var r = true;
      form.forEach(function(f) {
        if (f.subtype === part || f.type === part) {
          r = false;
        }
      });
      return r;
    }
  }
};
</script>
<style lang="sass" scoped></style>
